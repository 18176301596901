<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <data-form
        :form="form"
        :form-title="$t('forms.edit')"
        :loading="loading"
        @submit="submit"
      >
        <actions
          slot="card-actions"
          crud-links="forms"
          controller="DynamicForms"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
      </data-form>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'forms-edit',
  components: {
    DataForm,
    Actions,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      form: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `forms/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const formId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(formId))
      } catch (err) {
        // console.log('Error fetching mti data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.form = u.data.data
    },
    async submit (form) {
      this.loading = true
      const formId = this.$route.params.id
      try {
        await this.$http.put(this.routeBuilder(formId), form)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
